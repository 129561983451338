<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Movimientos</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Detalle de Movimientos Almacen</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row mb-4"
    >
      <h2><strong>DETALLE MOVIMIENTOS DE ALMACEN</strong></h2>
    </div>
    <p><strong>TIPO DE MOVIMIENTO:</strong> {{ movimiento.tipo_movalmacen }}</p>
    <div class="grid mt-4">
      <DataTable
        ref="dtmovimientos"
        :value="detalle_mov_almacen"
        class="col-12"
      >
        <Column field="mov_almacen_id" header="CÓDIGO MOVIMIENTO" />

        <Column field="nombre_producto" header="PRODUCTO/DESCRIPCIÓN">
          {{ detalle_mov_almacen.nombre_producto }}
        </Column>
        <Column field="cantidad" header="CANTIDAD" />
        <Column field="precio_compra" header="PUCompra">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.stock.cpp ?? 0
              )
            }}
          </template>
        </Column>
        <Column field="precio_sugerido" header="PUVentas">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.stock.ultimo_precio ?? 0
              )
            }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalCompra">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.stock.cpp
              )
            }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalVenta">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.stock.ultimo_precio
              )
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button
        @click="goBack"
        class="p-button-primary p-button-lg"
        v-tooltip.top="'Volver a Movimientos'"
      >
        <i class="pi pi-arrow-left"></i>&nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="IMPRIMIR"
        icon="pi pi-print"
        class="p-button-danger ml-2 p-button-lg"
        v-tooltip.top="'Imprimir PDF'"
        @click="imprimirMovimiento(movimiento.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import TipoMovimientos from "@/service/TipoMovimientos";
export default {
  data() {
    return {
      movimiento: {},
      mov_almacen: this.$route.params.id,
      detalle_mov_almacen: [],
      imprimiendo: false,
    };
  },
  tipoMovimientoService: null,
  created() {
    this.tipoMovimientoService = new TipoMovimientos();
  },
  mounted() {
    this.cargarMovimientos();
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    imprimirMovimiento(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.tipoMovimientoService.imprimirMov(id).then(() => {
        this.imprimiendo = false;
      });
    },
    cargarMovimientos() {
      this.tipoMovimientoService
        .getTipoMovimiento(this.mov_almacen)
        .then((data) => {
          this.movimiento = data.movimiento;
          this.detalle_mov_almacen = data.detalle;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
